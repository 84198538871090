<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Marka"
          label-for="brand"
        >
          <validation-provider
            #default="{ errors }"
            name="Marka"
            rules="required"
          >
            <v-select
              id="brand"
              v-model="consultantGoal.id_com_brand"
              :options="brands"
              label="name"
              :reduce="brand => brand.id"
              placeholder="Marka"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Danışman"
          label-for="users"
        >
          <validation-provider
            #default="{ errors }"
            name="Danışman"
            rules="required"
          >
            <v-select
              id="users"
              v-model="consultantGoal.id_com_consultant"
              :options="users"
              label="name"
              :reduce="user => user.id"
              placeholder="Danışman"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Satış Hedefi"
          label-for="goal"
        >
          <validation-provider
            #default="{ errors }"
            name="Hedef"
            rules="required"
          >
            <b-form-input
              id="goal"
              v-model="consultantGoal.goal"
              placeholder="Hedef"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ay"
          label-for="month"
        >
          <validation-provider
            #default="{ errors }"
            name="Ay"
            rules="required"
          >
            <v-select
              id="month"
              v-model="consultantGoal.month"
              :options="months"
              label="title"
              :reduce="item => item.id"
              placeholder="Ay"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Yıl"
          label-for="year"
        >
          <validation-provider
            #default="{ errors }"
            name="Yıl"
            rules="required"
          >
            <b-form-input
              id="year"
              v-model="consultantGoal.year"
              placeholder="Yıl"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Web Görüşme Adedi"
          label-for="web"
        >
          <validation-provider
            #default="{ errors }"
            name="Web Görüşme Adedi"
            rules="required"
          >
            <b-form-input
              id="web"
              v-model="consultantGoal.web"
              placeholder="Web Görüşme Adedi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Müşteri Görüşme Hedefi"
          label-for="new_customer"
        >
          <validation-provider
            #default="{ errors }"
            name="Müşteri Görüşme Hedefi"
            rules="required"
          >
            <b-form-input
              id="test_drive"
              v-model="consultantGoal.new_customer"
              placeholder="Müşteri Görüşme Hedefi"
              readonly
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Test Sürüşü Hedefi"
          label-for="test_drive"
        >
          <validation-provider
            #default="{ errors }"
            name="Test Sürüşü Hedefi"
            rules="required"
          >
            <b-form-input
              id="test_drive"
              v-model="consultantGoal.test_drive"
              placeholder="Test Sürüşü Hedefi"
              readonly
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Dijital Satış"
          label-for="digital_goal"
        >
          <validation-provider
            #default="{ errors }"
            name="Dijital Satış"
            rules="required"
          >
            <b-form-input
              id="digital_goal"
              v-model="consultantGoal.digital_goal"
              placeholder="Dijital Satış"
              readonly
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import vSelect from 'vue-select'
import {
  BCard, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'

export default {
  name: 'ItemFormNew',
  components: {
    BFormInput, ValidationProvider, BCard, BFormGroup, BRow, BCol, vSelect,
  },
  data() {
    return {
      months: [
        {
          id: 1,
          title: 'Ocak',
        },
        {
          id: 2,
          title: 'Şubat',
        },
        {
          id: 3,
          title: 'Mart',
        },
        {
          id: 4,
          title: 'Nisan',
        },
        {
          id: 5,
          title: 'Mayıs',
        },
        {
          id: 6,
          title: 'Haziran',
        },
        {
          id: 7,
          title: 'Temmuz',
        },
        {
          id: 8,
          title: 'Ağustos',
        },
        {
          id: 9,
          title: 'Eylül',
        },
        {
          id: 10,
          title: 'Ekim',
        },
        {
          id: 11,
          title: 'Kasım',
        },
        {
          id: 12,
          title: 'Aralık',
        },
      ],
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    consultantGoal() {
      return this.$store.getters['consultantGoals/getConsultantGoal']
    },
  },
  watch: {
    consultantGoal: {
      deep: true,
      handler(val) {
        this.setGoals(val.goal, val.web)
      },
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    setGoals(goal, web) {
      if (goal) {
        this.consultantGoal.new_customer = Math.floor(Number(goal) * 3)
        this.consultantGoal.test_drive = Math.floor(Number(goal) * 0.85)
        this.consultantGoal.digital_goal = Math.floor(Number(web) * 0.05)
      } else {
        this.consultantGoal.new_customer = 0
        this.consultantGoal.test_drive = 0
        this.consultantGoal.digital_goal = 0
      }
    },
  },
}
</script>
