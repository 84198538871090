var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Marka","label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"Marka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"brand","options":_vm.brands,"label":"name","reduce":function (brand) { return brand.id; },"placeholder":"Marka"},model:{value:(_vm.consultantGoal.id_com_brand),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "id_com_brand", $$v)},expression:"consultantGoal.id_com_brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Danışman","label-for":"users"}},[_c('validation-provider',{attrs:{"name":"Danışman","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"users","options":_vm.users,"label":"name","reduce":function (user) { return user.id; },"placeholder":"Danışman"},model:{value:(_vm.consultantGoal.id_com_consultant),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "id_com_consultant", $$v)},expression:"consultantGoal.id_com_consultant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Satış Hedefi","label-for":"goal"}},[_c('validation-provider',{attrs:{"name":"Hedef","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"goal","placeholder":"Hedef"},model:{value:(_vm.consultantGoal.goal),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "goal", $$v)},expression:"consultantGoal.goal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Ay","label-for":"month"}},[_c('validation-provider',{attrs:{"name":"Ay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"month","options":_vm.months,"label":"title","reduce":function (item) { return item.id; },"placeholder":"Ay"},model:{value:(_vm.consultantGoal.month),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "month", $$v)},expression:"consultantGoal.month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Yıl","label-for":"year"}},[_c('validation-provider',{attrs:{"name":"Yıl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"year","placeholder":"Yıl"},model:{value:(_vm.consultantGoal.year),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "year", $$v)},expression:"consultantGoal.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Web Görüşme Adedi","label-for":"web"}},[_c('validation-provider',{attrs:{"name":"Web Görüşme Adedi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"web","placeholder":"Web Görüşme Adedi"},model:{value:(_vm.consultantGoal.web),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "web", $$v)},expression:"consultantGoal.web"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Müşteri Görüşme Hedefi","label-for":"new_customer"}},[_c('validation-provider',{attrs:{"name":"Müşteri Görüşme Hedefi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"test_drive","placeholder":"Müşteri Görüşme Hedefi","readonly":""},model:{value:(_vm.consultantGoal.new_customer),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "new_customer", $$v)},expression:"consultantGoal.new_customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Test Sürüşü Hedefi","label-for":"test_drive"}},[_c('validation-provider',{attrs:{"name":"Test Sürüşü Hedefi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"test_drive","placeholder":"Test Sürüşü Hedefi","readonly":""},model:{value:(_vm.consultantGoal.test_drive),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "test_drive", $$v)},expression:"consultantGoal.test_drive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Dijital Satış","label-for":"digital_goal"}},[_c('validation-provider',{attrs:{"name":"Dijital Satış","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"digital_goal","placeholder":"Dijital Satış","readonly":""},model:{value:(_vm.consultantGoal.digital_goal),callback:function ($$v) {_vm.$set(_vm.consultantGoal, "digital_goal", $$v)},expression:"consultantGoal.digital_goal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }